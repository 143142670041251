import ReactGA from 'react-ga4';

import { isCordova, isLocalHost, ROUTES } from 'other';
import { store, history, TState } from 'store';
import { TIMER_DELAY } from 'components';

import { ETrialModalActions } from './trialModalConstants';

let historyUnsubscribe;

export function shouldShow(isLogged: boolean): boolean {
  const shouldShow = sessionStorage.getItem('TRIAL_MODAL') === 'true';
  if (isCordova() || isLogged || shouldShow) return false;

  sessionStorage.setItem('TRIAL_MODAL', 'true');
  return true;
}

export function hideTrialModalAction() {
  return (dispatch) => {
    sessionStorage.setItem('TRIAL_MODAL', 'false');

    dispatch({
      payload: {
        isModalVisible: false
      },
      type: ETrialModalActions.HIDE_MODAL
    });
  };
}

export function showTrialModalAction(timerId) {
  return (dispatch) => {
    historyUnsubscribe && historyUnsubscribe();

    if (!isLocalHost()) {
      ReactGA.event({
        action: 'Trial Modal is shown',
        category: 'Trial',
        label: JSON.stringify({ location: window.location.pathname })
      });
    }

    dispatch({
      payload: {
        isModalVisible: true,
        timerId: timerId
      },
      type: ETrialModalActions.SHOW_MODAL
    });
  };
}

export function setTimerTrialModalAction() {
  return (dispatch, getState) => {
    const { session } = getState() as TState;
    const isLogged = !!session.user?.token;
    const isLoginPage = location.pathname === ROUTES.LOGIN;

    if (!shouldShow(isLogged)) return;

    if (isLoginPage) {
      sessionStorage.setItem('TRIAL_MODAL', 'false');
      return;
    }

    const timerId = setTimeout(
      () => dispatch(showTrialModalAction(timerId)),
      TIMER_DELAY
    );

    listenHistory();

    dispatch({
      payload: { timerId: timerId },
      type: ETrialModalActions.SET_TIMER
    });
  };
}

export function clearTrialModalTimerAction() {
  return (dispatch, getState) => {
    const { trialModal } = getState() as TState;

    clearTimeout(trialModal.timerId);
    historyUnsubscribe && historyUnsubscribe();
    sessionStorage.setItem('TRIAL_MODAL', 'false');

    dispatch({
      payload: {
        isModalVisible: false,
        timerId: null
      },
      type: ETrialModalActions.CLEAR_TIMER
    });
  };
}

function listenHistory() {
  historyUnsubscribe = history.listen((location) => {
    if (location.pathname === ROUTES.LOGIN) {
      store.dispatch(clearTrialModalTimerAction());
    }
  });
}
