import ReactGA from 'react-ga4';

import { BREAKPOINTS } from 'other';

export type TShareInfo = {
  category: string;
  id: number;
  title: string;
};

export class SharingService {
  private static readonly WINDOW_PARAMS =
    'toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no';

  private static getParams(): string {
    const height = Math.floor(0.8 * document.documentElement.offsetHeight);
    const width =
      document.documentElement.offsetWidth < BREAKPOINTS.MD
        ? 0.9 * document.documentElement.offsetWidth
        : 600;

    const left = Math.floor((window.screen.width - width) / 2);
    const top = Math.floor((window.screen.height - height) / 2);
    return `${
      SharingService.WINDOW_PARAMS
    }, height=${height}, width=${Math.floor(width)}, left=${left}, top=${top}`;
  }

  public static shareOnFB = ({ category, id, title }: TShareInfo): void => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&t=${title}`;
    window.open(url, 'facebook_share', SharingService.getParams());

    ReactGA.event({
      action: `Facebook Share ${category} is clicked`,
      category: 'Facebook Share',
      label: JSON.stringify({ id: id, type: category })
    });
  };

  public static shareOnLinkedIn = ({ category, id }: TShareInfo): void => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
    window.open(url, 'linkedin_share', SharingService.getParams());

    ReactGA.event({
      action: `Linked In ${category} is clicked`,
      category: 'Linked In Share',
      label: JSON.stringify({ id: id, type: category })
    });
  };
}
