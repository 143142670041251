import { configureScope } from '@sentry/browser';
import platform from 'platform';
import { push } from 'connected-react-router';
import ReactGA from 'react-ga4';

import { autoLoginAction } from '../login/loginActions';
import {
  checkSessionSet,
  ESessionActions,
  updateSessionSet
} from './sessionConstants';
import { clearTrialModalTimerAction } from '../trialModal/trialModalActions';
import { ENDPOINTS, isLocalHost, ROUTES } from 'other';
import {
  getCredentialsFromURL,
  getToken,
  http,
  runPushService,
  SettingsService,
  THttpResponse
} from 'services';

import { TAction, TSessionState, TState } from 'store';
import { TUser } from 'types';

/**
 * Checks whether a user session is still valid.
 */
export function checkSessionAction() {
  return async (dispatch) => {
    if (getCredentialsFromURL()) {
      return await dispatch(autoLoginAction());
    }

    if (!getToken()) return;
    dispatch(checkSessionSet.request());

    try {
      const resp: THttpResponse<TUser> = await http.send(ENDPOINTS.SESSION);

      configureScope((scope) =>
        scope.setUser({
          id: String(resp.data.userInfo.id)
        })
      );

      dispatch(checkSessionSet.success({ user: resp.data }));
      dispatch(clearTrialModalTimerAction());
      dispatch(followRedirectPathAction());
      reportLogin(resp.data.userInfo.id);
      runPushService(resp.data.token);
    } catch (e) {
      dispatch(checkSessionSet.error(e));
      dispatch({
        type: ESessionActions.INVALIDATE_SESSION,
        payload: { user: null }
      });
    }
  };
}

/**
 * Shadow session update to keep in sync the user's fleets, providers and events.
 */
export function updateSessionAction() {
  return (dispatch) => {
    // we do not set `isLoading = true` here, because it will rerender the whole application!
    dispatch({ type: ESessionActions.UPDATE_SESSION_REQUEST });

    http
      .send(ENDPOINTS.SESSION)
      .then((resp: THttpResponse<TUser>) =>
        dispatch(updateSessionSet.success({ user: resp?.data || null }))
      )
      .catch((e) => dispatch(updateSessionSet.error(e)));
  };
}

/**
 * Assigns session data.
 * @param user
 */
export function storeSessionAction(user: TUser) {
  return (dispatch) => {
    if (user) {
      reportLogin(user.userInfo.id);
      dispatch(clearTrialModalTimerAction());
    }

    dispatch({
      type: ESessionActions.STORE_SESSION,
      payload: {
        error: null,
        user: user
      }
    });
  };
}

/** Stores given routing path. */
export const storeRedirectPathAction = (): TAction<
  TSessionState,
  ESessionActions
> => ({
  type: ESessionActions.STORE_REDIRECT_PATH,
  payload: {
    redirectPath: window.location.pathname + window.location.search
  }
});

/**/
export function followRedirectPathAction() {
  return (dispatch, getState) => {
    const {
      session: { redirectPath }
    } = getState() as TState;

    if (redirectPath && redirectPath !== window.location.pathname) {
      dispatch(push(redirectPath || ROUTES.HOME));
    }
  };
}

/**/
export function toggleConnectionQualityAction() {
  return (dispatch, getState) => {
    const { session } = getState() as TState;
    const value = !session.isConnectionPoor;

    dispatch({
      type: ESessionActions.TOGGLE_INTERNET_MODE,
      payload: {
        isConnectionPoor: value
      }
    });

    SettingsService.writeSettings({
      [SettingsService.IS_SLOW_INTERNET]: value
    });
  };
}

/**/
function reportLogin(userId: number) {
  if (isLocalHost()) return;

  const label = {
    userId: userId,
    userAgentName: platform.name,
    userAgentVersion: platform.version
  };
  ReactGA.event({
    action: 'Login',
    category: 'User',
    label: JSON.stringify(label)
  });
}
